/* eslint-disable react-hooks/exhaustive-deps */


export default function HomeDesktop() {
  return (
    <div className="w-full lg:px-[25%]">
      <section className="flex justify-center items-center w-full">
        <img
          src="https://image.mail.copec.cl/lib/fe2f11737164047d7d1078/m/1/239acb4c-8078-4c7c-a16a-e8f1c8ede8f3.png"
          alt="header"
          className="w-full"
        />
      </section>
      <section className="flex flex-col justify-center items-center w-full py-5 text-[#0055b8] px-[5%]">
        <h2 className="font-bold text-[20px] lg:text-[26px]">Cerramos esta etapa</h2>
        <h3 className="font-bold text-[20px] lg:text-[26px]">
          ¡pero estaremos siempre cerca de ti!
        </h3>
        <p className="lg:text-[18px] text-center pt-5">
          Los servicios de <strong>Copec a domicilio</strong> (kerosene, lavado
          de auto y cambio de aceite) pronto dejarán de estar disponibles.
        </p>
      </section>
      <section className="flex justify-center items-center px-[10%]">
        <img
          src="https://image.mail.copec.cl/lib/fe2f11737164047d7d1078/m/1/fb3fa052-f848-4ce2-848d-72cf31d1f6cd.png"
          alt="body"
          className="w-full"
        />
      </section>
      <section className="flex flex-col justify-center items-center w-full py-5 text-[#0055b8] px-[7%]">
        <p className="lg:text-[18px] text-center pt-5">
          Visita tu estación favorita y sigue disfrutando de la calidad y
          atención que nos caracteriza. Aprovecha tus ultimos pedidos, ¡el
          servicio estará disponible <strong>hasta el 1 de marzo!</strong>
        </p>
        <button className="border-[2px] border-[#0055b8] text-[#0055b8] px-8 py-2 rounded-xl mt-5">
          <a
            href="https://ww2.copec.cl/personas/estaciones-de-servicio"
            target="_blank"
            className="font-bold"
          >
            Encuentra la más cercana
          </a>
        </button>
      </section>
      <section className="flex flex-col justify-center items-center px-[5%] lg:px-[10%] text-[#0055b8] lg:text-[18px] font-bold mt-5">
        <p>
            Tu preferencia ha sido nuestra mayor recompensa, 
        </p>
        <p>
            ¡gracias por acompañarnos en este camino!
        </p>
      </section>
      <section className="flex justify-center items-center w-full mt-7 pb-7">
        <img
          src="https://image.mail.copec.cl/lib/fe2f11737164047d7d1078/m/7/129979a3-cde7-4c82-a497-46ae6d121ec7.png"
          alt="footer"
          className="w-[180px]"
        />
      </section>
    </div>
  );
}