/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Images from '../../assets/image';
import Image from '../Image';
import '../../styles/components/component.address-seted.scss';
import { useHistory, useParams } from 'react-router';
import ConfirmationModal from '../ConfirmationModal';
import { clear_services, remove_service } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { clearDirections } from '../../redux/actions/directions';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { ReactComponent as TrashIcon } from '../../assets/image/svg/trash.svg';
import { send_message } from '../../redux/actions/broadcast';

const AddressSeted = (props: any) => {
  const { onClick, bgWhite, addresses, ...rest } = props;

  const [data, setData] = useState({ title: '', subtitle: '' });
  const [state, setState] = useState({
    vehicle: { active: false, plate: '', service_id: '' },
    modal: { open: false },
    direction_modal: { open: false },
    home: true,
  });

  const [isCompanyFlow, setIsCompanyFlow] = useState<any>([]);

  const { directions, services, matchMedia, available_services, user } = useSelector((state: any) => state);
  const { direction } = directions;
  const history: any = useHistory();
  const dispatch = useDispatch();
  const params: { slug: string } = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const isCompanyFlow = available_services.filter((as: any) => as?.info?.is_company === 1);
    setIsCompanyFlow(isCompanyFlow);
  }, [available_services]);

  useEffect(() => {
    try {
      const splited = direction ? direction.split(',') : null;
      if (splited) {
        if (splited.length > 1) {
          const data: any = {
            title: splited[0],
          };
          splited.splice(0, 1);
          data.subtitle = splited.join(' ').trim();
          setData(data);
        } else {
          const data: any = {
            title: splited[0],
            subtitle: splited[0],
          };
          setData(data);
        }
      }
    } catch (e) {
      console.log('[LOG]', e);
    }
  }, [direction]);

  useEffect(() => {
    const isServiceAvailable = available_services.find((as: any) => as.info?.slug === params.slug);

    if (isServiceAvailable) {
      let service = services.find((service: any) => service.PK === isServiceAvailable.PK && !service.is_service_in_cart);

      if (urlParams.has('id')) {
        if (urlParams.has('id')) {
          const existsServiceWithThisId = services.find((service: any) => service.id === urlParams.get('id'));
          if (existsServiceWithThisId) service = existsServiceWithThisId;
        }
      }

      if (service && service.car) {
        setState({ ...state, vehicle: { active: true, plate: service.car.plate, service_id: service.id }, home: history.location.pathname === '/' ? true : false });
      } else {
        setState({ ...state, vehicle: { active: false, plate: '', service_id: '' }, home: history.location.pathname === '/' ? true : false });
      }
    }
  }, [services]);

  const _toogleModal = () => setState({ ...state, modal: { open: !state.modal.open } });
  const _toogleModalDirection = () => setState({ ...state, direction_modal: { open: !state.direction_modal.open } });

  const _handleClearVehicle = () => {
    if (urlParams.has('id')) {
      dispatch(send_message({ update_car: true }));
    }

    if (!urlParams.has('id')) {
      const isServiceAvailable = available_services.find((as: any) => as.info?.slug === params.slug);
      if (isServiceAvailable) {
        const service = services.find((service: any) => service.PK === isServiceAvailable.PK && !service.is_service_in_cart);
        dispatch(remove_service({ id: service.id }));
      }
    }
    _toogleModal();
  };

  const _handleClearDirection = () => {
    dispatch(LOAD_ON());
    dispatch(clear_cart());
    dispatch(clearDirections());
    dispatch(clear_services());
    dispatch(LOAD_OFF());
  };

  const _handleFirstDrawer = () => {
    if (user.isLogged && addresses.length > 0) {
      onClick('history');
    } else {
      onClick('autocomplete');
    }
  };

  if (isCompanyFlow && isCompanyFlow.length > 0) {
    return (
      <div className={`container-address-seted m-0 container-fluid ${bgWhite ? 'bg-white' : ''}`} {...rest}>
        <div className="row w-100 m-0 px-0">
          <div className={`${state.home || state.vehicle.active ? 'col-12 d-flex justify-content-between ps-2 ps-sm-5' : 'col-12 d-flex justify-content-between px-4 px-sm-5'}`}>
            <div className="d-flex align-items-center" style={{ width: '90%' }}>
              <i className="bi bi-building" />
              <div style={{ width: '90%' }}>
                <div className="bold color-black" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {isCompanyFlow[0]?.company?.name}
                </div>
                <div className="size-09" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  Servicio tipo empresa
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`container-address-seted m-0 container-fluid ${bgWhite ? 'bg-white' : ''}`} {...rest}>
      <ConfirmationModal open={state.modal.open} handleConfirm={_handleClearVehicle} handleClose={_toogleModal} title="Atención" type="warning" size={matchMedia.media === 'xs' ? 'auto' : 'md'}>
        <span className="bold-300">¿Seguro que deseas cambiar tu vehículo?</span>
      </ConfirmationModal>

      <ConfirmationModal
        open={state.direction_modal.open}
        handleConfirm={_handleClearDirection}
        handleClose={_toogleModalDirection}
        title="Atención"
        type="warning"
        size={matchMedia.media === 'xs' ? 'auto' : 'md'}
      >
        <span className="bold-300">Si eliminas tu dirección, se vaciará el carrito de compras. ¿Estás seguro de que deseas eliminar tu dirección?</span>
      </ConfirmationModal>

      <div className="row w-100 m-0 px-0">
        <div
          className={`${state.home || state.vehicle.active ? 'col-9 d-flex justify-content-between ps-2 ps-sm-5' : 'col-12 d-flex justify-content-between px-4 px-sm-5'}`}
          style={{ cursor: 'pointer' }}
          onClick={_handleFirstDrawer}
        >
          <div className="d-flex align-items-start" style={{ width: '90%' }}>
            <i className="bi bi-geo-alt" />
            <div style={{ width: '90%' }}>
              <div className="bold color-black" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {data.title}
              </div>
              <div className="size-09" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {data.subtitle}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center me-0 me-md-3">
            <Image image={Images.ArrowDown} />
          </div>
        </div>

        {state.vehicle.active && (

          <div className="col-3" style={{ borderLeft: '2px solid #e7ebf6', cursor: 'pointer' }} onClick={() => _toogleModal()}>
     

            <div className="text-center w-100 size-09 bold-300" style={{ textTransform: 'uppercase' }}>
              
            </div>
          </div>
        )}

        {state.home && (
          <div
            className="col-3 d-flex align-items-center justify-content-start size-09 ps-3 ps-md-4"
            style={{ borderLeft: '2px solid #e7ebf6', cursor: 'pointer' }}
            onClick={() => _toogleModalDirection()}
          >
            <TrashIcon className="me-2 d-none d-md-block" style={{ width: '17px' }} />
            <span className="bold-300">Eliminar dirección</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressSeted;
